<template>
  <div class="detail-page">
    <Header />
    <div class="catename"><tag class="van-tag van-tag--round van-tag--warning">{{ cateName }}</tag></div>
    <div class="title">{{ title }}</div>
    <div class="content">
      <span class="time">{{ createTime }}</span>
      <span class="scannum"><img class="team-icon" src="../../../assets/images/home/view.png" />{{ scanNum }}人阅读</span>
    </div>
    <div class="content2" v-html="context"></div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import {articleAgroInfo} from '@/api/agro';

export default {
  components: {
    Header,
  },
  data() {
    return {
      title: '标题',
      createTime:'',
      scanNum:0,
      context: '',
      cateName:'',
    }
  },
  beforeMount() {
    this.title = this.$route.params.data.title;
    this.createTime = this.$route.params.data.createTime;
    this.scanNum = this.$route.params.data.scanNum;
    this.cateName = this.$route.params.data.cateName;
    this.getArticleAgro(this.$route.params.data.id);
   
  },
  methods: {
    async getArticleAgro(id) {
      const res =  await articleAgroInfo({id:id});
      this.context = res.data.detail;
      this.context = this.context.replace(/&lt;/gs, '<');
      this.context = this.context.replace(/&gt;/gs, '>');
      this.context = this.context.replace(/&amp;nbsp;/gs, ' ');
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  .title {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    font-size: 14px;
    color: #2C2C2C;
    text-align: left;
    font-weight: bold;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 5px;
    font-size: 12px;
    color: #818181;
    word-wrap:break-word;
    text-align: left;
  }
  .content2 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 5px;
    font-size: 12px;
    color: #818181;
    word-wrap:break-word;
    text-align: left;
  }

  .catename{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    padding-left:20px;
    line-height: 14px;
    color: #2c2c2c;
    text-align: left;
  }
  .time{
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    color: #2c2c2c;
  }
  .scannum{
    margin-left: 15px;
    font-size: 12px;
    line-height: 14px;
    color: #2c2c2c;
  }
  .team-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }

}
</style>
<style type="text/css" lang="scss">
  .content2 img {
    width: 100%;
  }
</style>
