<template>
  <div class="detail-page">
    <Header />
    <div class="title">{{title}}</div>
    <div class="content" v-html="context"></div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'

export default {
  components: {
    Header,
  },
  data() {
    return {
      title: '标题',
      context: ''
    }
  },
  beforeMount() {
    this.title = this.$route.params.title
    this.context = this.$route.params.context
    this.context = this.context.replace(/&lt;/gs, '<')
    this.context = this.context.replace(/&gt;/gs, '>')
    this.context = this.context.replace(/&amp;nbsp;/gs, ' ')
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  .title {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    margin-top: 37px;
    font-size: 14px;
    color: #2C2C2C;
    text-align: left;
    font-weight: bold;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 12px;
    font-size: 12px;
    color: #818181;
    word-wrap:break-word;
    text-align: left;
  }

}
</style>