<template>
  <div class="list-page">
    <Header />
    <div class="title">
      关联功能：功能名称
    </div>
    <RowLink :links="links" @click="handleClick" />
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import RowLink from '@/components/select/rowLink.vue'
import { fetchTeachingMaterialList } from '@/api/material'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Header,
    RowLink,
    InfiniteLoading,
  },
  data() {
    return {
      // links: [
      //   {label: '终端拜访', path: ''},
      //   {label: '开发商速成', path: ''},
      //   {label: '经销商拜访', path: ''},
      // ],
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
    }
  },
  computed: {
    links() {
      let ret = []
      this.list.map(item=>{
        ret.push({
          label: item.titlle,
          event: 'click',
          context: item.context
        })
      })  
      return ret
    }
  },
  methods: {
    handleClick(link) {
      this.$router.push({
        name: 'TeachingMaterialDetail',
        params: {
          title: link.label,
          context: link.context
        }
      })
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let response = await fetchTeachingMaterialList({
        "id": "terminal:visit",
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      })
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
  }
}
</script>

<style lang="scss" scoped>
.list-page {
  width: 100%;
  height: 100vh;
  font-size: 12px;
  text-align: left;
  background: #FAFBFC;
  .title {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    line-height: 42px;
    padding-left: 24px;
    align-items: center;
    color: #818181;
  }
  .link-container {
    width: 100%;
    background: white;
  }
}
</style>