<template>
  <div class="detail-page">
    <Header />

    <div class="content">
      <iframe :src="url" class="frame-content"></iframe>
    </div>

  </div>
</template>

<script>
import Header from '@/components/header/index'


export default {
  components: {
    Header,
  },
  data() {
    return {
      url: '',
    }
  },
  mounted () {
    this.url = 'http://ebstest.shidanli.cn/crm/getArticleAgroDetail?id=' + this.$route.params.id;
    // this.url = 'https://ebsc.shidanli.cn/getArticleAgroDetail?id=' + this.$route.params.id;
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 97vh;
  padding-bottom: 20px;
  .content {
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    color: #818181;
    word-wrap:break-word;
    text-align: left;
    .frame-content {
      border: medium none;
      width: 99%;
      min-height: 85vh;
      margin: 0 2px;
    }
  }

}
</style>
<style type="text/css" lang="scss">
  .content img {
    width: 100%;
  }
</style>
